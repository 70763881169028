import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authconfig";
// import { Button, Typography } from "antd";
// const { Title } = Typography;
import CbreButton from "./CbreButton";
/**
 * Renders a button which, when selected, will open a popup for login
 */

export const SignInButton = () => {
  const { instance } = useMsal();
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const handleLogin = async (loginType) => {
    console.log("[FE]Testing", loginType);
    try {
      if (loginType === "popup") {
        console.log("[FE]Login Reqest", loginRequest);
        const response =  await instance.loginPopup(loginRequest);
        console.log('[FE]Login response: ', response);
      }
    } catch(error){
       console.log('[FE]Login error: ', error);
    }
  };
  return (
    <CbreButton onClick={() => handleLogin("popup")}>
      Sign in
    </CbreButton>
  );
};
